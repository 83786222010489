/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        //Adds a loaded class to video so we can fade in if desired
        $('.home-hero.video video').on('canplay canplaythrough', function() {
          $(this).addClass('loaded');
        });

        $('select').on('change', function(evt) {
          $(this).addClass('not-placeholder');
        });

        var gravityFormsSelect2 = function() {
          $('select').select2({
            dropdownAutoWidth: true,
            minimumResultsForSearch: Infinity,
            width: '100%'
          });
        };
        gravityFormsSelect2();
        $(document).on('gform_post_render', function() {
          gravityFormsSelect2();
        });

        //Grabs the name of the most recent position that's shown and assigns
        //our hidden field's value to it so that admins can see which position
        //was applied to
/*
        $(document).on('shown.bs.collapse', '#positions-accordion', function() {
          var tabName = $(this).find('.show').siblings('[role=tab]').find('a').html().trim();
          $(this).closest('.positions').siblings('.application-form').find('.gfield.gform_hidden input').attr('value', tabName);
        });
*/
        //Change our faux file upload button text to match the filename that
        //was uploaded
        $(document).on('change', '.gfield input[type=file]', function() {
          var fileName = this.files[0].name;
          $(this).closest('.ginput_container').siblings('label').html(fileName);
        });

        $(document).on('submit', '.gform_wrapper form', function() {
          $(this).find('.preloader').addClass('active');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
